import React, { useEffect, useState } from 'react';
import './userSubscription.css';
import Navbar from '../../components/CustomNavbar';
import { GetAllPurchasedAudioAlbumListApi } from "../../services/audioAlbum";
import { Button } from 'reactstrap';

const UserVideoPurchaseList = () => {
    const [groupedSubscriptions, setGroupedSubscriptions] = useState({});
    const [groupByTitle, setGroupByTitle] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        const fetchSubscriptionList = async () => {
            try {
                const response = await GetAllPurchasedAudioAlbumListApi();
                console.log(response.data);

                const grouped = groupByTitle ? groupSubscriptionsByTitle(response.data) : groupSubscriptionsByUser(response.data);
                setGroupedSubscriptions(grouped);

                // Calculate the total price
                const total = calculateTotalPrice(grouped, groupByTitle);
                setTotalPrice(total);
            } catch (err) {
                console.error(err);
            }
        };

        fetchSubscriptionList();
    }, [groupByTitle]);

    const groupSubscriptionsByUser = (data) => {
        return data.reduce((acc, item) => {
            const userName = item.user?.user_name || 'Unknown User';

            if (!acc[userName]) {
                acc[userName] = {
                    email: item.user?.email || 'No Email',
                    contact: item.user?.contact || 'No Contact',
                    purchases: [],
                    transactionDates: [],
                };
            }

            const title = item.videoData ? item.videoData.title : (item.audioAlbum ? item.audioAlbum.title : 'N/A');
            const price = item.videoData ? item.videoData.price : (item.audioAlbum ? item.audioAlbum.price : 0);
            const transactionDate = new Date(item.transaction?.transactionDate).toLocaleDateString();

            acc[userName].purchases.push({ title, price });
            acc[userName].transactionDates.push(transactionDate);

            return acc;
        }, {});
    };

    const groupSubscriptionsByTitle = (data) => {
        return data.reduce((acc, item) => {
            const title = item.videoData ? item.videoData.title : (item.audioAlbum ? item.audioAlbum.title : 'N/A');

            if (!acc[title]) {
                acc[title] = {
                    userCount: 0,
                    users: [],
                    prices: [],
                    transactionDates: [],
                };
            }

            const userName = item.user?.user_name || 'Unknown User';
            const price = item.videoData ? item.videoData.price : (item.audioAlbum ? item.audioAlbum.price : 0);
            const transactionDate = new Date(item.transaction?.transactionDate).toLocaleDateString();

            acc[title].userCount += 1;
            acc[title].users.push(userName);
            acc[title].prices.push(price);
            acc[title].transactionDates.push(transactionDate);

            return acc;
        }, {});
    };

    const calculateTotalPrice = (grouped, groupByTitle) => {
        let total = 0;

        if (groupByTitle) {
            Object.values(grouped).forEach((details) => {
                total += details.prices.reduce((sum, price) => sum + price, 0);
            });
        } else {
            Object.values(grouped).forEach((user) => {
                total += user.purchases.reduce((sum, purchase) => sum + purchase.price, 0);
            });
        }

        return total;
    };

    const toggleView = () => {
        setGroupByTitle(!groupByTitle);
    };

    return (
        <div>
            <Navbar />
            <div className="subscription-table">
                <Button className='group_by_button'  onClick={toggleView}>
                    {groupByTitle ? 'Group by User' : 'Group by Title'}
                </Button>
                
                <table>
                    <thead>
                        <tr>
                            {groupByTitle ? (
                                <>
                                    <th>Title</th>
                                    <th>User Count</th>
                                    <th>Users</th>
                                    <th>Prices</th>
                                    <th>Transaction Dates</th>
                                </>
                            ) : (
                                <>
                                    <th>User Name</th>
                                    <th>Email</th>
                                    <th>Contact</th>
                                    <th>Titles</th>
                                    <th>Prices</th>
                                    <th>Transaction Dates</th>
                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {groupByTitle ? (
                            Object.entries(groupedSubscriptions).map(([title, details], index) => (
                                <tr key={index}>
                                    <td>{title}</td>
                                    <td>{details.userCount}</td>
                                    <td>
                                        {details.users && details.users.map((user, idx) => (
                                            <div key={idx}>{user}</div>
                                        ))}
                                    </td>
                                    <td>
                                        {details.prices && details.prices.map((price, idx) => (
                                            <div key={idx}>{price}</div>
                                        ))}
                                    </td>
                                    <td>
                                        {details.transactionDates && details.transactionDates.map((date, idx) => (
                                            <div key={idx}>{date}</div>
                                        ))}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            Object.entries(groupedSubscriptions).map(([userName, user], index) => (
                                <tr key={index}>
                                    <td>{userName}</td>
                                    <td>{user.email}</td>
                                    <td>{user.contact}</td>
                                    <td>
                                        {user.purchases && user.purchases.map((purchase, idx) => (
                                            <div key={idx}>{purchase.title}</div>
                                        ))}
                                    </td>
                                    <td>
                                        {user.purchases && user.purchases.map((purchase, idx) => (
                                            <div key={idx}>{purchase.price}</div>
                                        ))}
                                    </td>
                                    <td>
                                        {user.transactionDates && user.transactionDates.map((date, idx) => (
                                            <div key={idx}>{date}</div>
                                        ))}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={groupByTitle ? 5 : 6} className="total-price">
                                <strong>Total Price: {totalPrice}</strong>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

export default UserVideoPurchaseList;